import axios from "axios";

class FileConverters {
  async toBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  base64toFile(dataUrl: string, filename: string) {
    const arr = dataUrl.split(",");
    // @ts-ignore
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  toBlob(file: File) {
    return URL.createObjectURL(file);
  }

  toArrayBuffer(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const fileByteArray: any = [];
      reader.readAsArrayBuffer(file);
      reader.onloadend = function (evt) {
        if (evt?.target?.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer as ArrayBuffer);
          for (let i = 0; i < array.length; i++) {
            fileByteArray.push(array[i]);
          }
        }
        resolve(fileByteArray);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  base64toBlob(b64Data = "", contentType = "application/pdf", sliceSize = 512) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  async getDownloadFileUrl(url: string) {
    try {
      const responsePromise = await fetch(url);
      const blob = await responsePromise.blob();
      return URL.createObjectURL(blob);
    } catch (e) {
      return url;
    }
  }

  downloadBase64(base64: string, fileName: string) {
    const downloadLink = document.createElement("a");
    downloadLink.href = base64;
    downloadLink.download = fileName;
    downloadLink.click();
    downloadLink.remove();
  }

  async downloadPdfFromUrlUsingBlob(url: string, fileName = "arquivo") {
    try {
      const response = await axios.get(url, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `${fileName}.pdf`;
      link.style.display = "none";
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Erro ao baixar o contrato:", error);
    }
  }
}

export default new FileConverters();
